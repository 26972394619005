<template>
  <section class="flex flex-col gap-2">
    <span class="font-bold text-xl flex justify-center">¿En donde te Recojo?</span>
    <div class="grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-4">
      <div class="flex flex-col gap-2 w-full">
        <label>Tipo Servicio</label>
        <Dropdown
          v-model="modelModal.guia.tipo"
          :options="tiposServicio"
          :disabled="inputsAvailable"
          optionLabel="nomTipo"
          optionValue="codTipo"
          placeholder="Seleccione un tipo de servicio"
          class="p-inputtext-sm w-full"
        />
        <MessageError :text="errorsModal['guia.tipo']"/>
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label>Centros de Costos</label>
        <Dropdown
          v-model="modelModal.origen.centro"
          :options="centrosCostos"
          :disabled="inputsAvailable"
          optionLabel="label"
          optionValue="value"
          placeholder="Seleccione un centro"
          class="p-inputtext-sm w-full"
        />
        <MessageError :text="errorsModal['origen.centro']"/>
      </div>
      <div class="flex flex-col" v-if="[1, 10, 24].includes(modelModal.guia.tipo)">
        <label>Tipo de empaque</label>
        <div class="flex w-full justify-around pt-3">
          <div v-for="empaque of empaques" :key="empaque.codEmpaque" class="field-radiobutton">
            <RadioButton :inputId="empaque.key" name="category" :value="empaque.codEmpaque"
                         v-model="modelModal.guia.empaque" :disabled="inputsAvailable"/>
            <label class="ml-2" :for="empaque.key">{{ empaque.nomEmpaque }}</label>
          </div>
        </div>
      </div>
      <MessageError :text="errorsModal['guia.empaque']"/>
    </div>
    <div class="grid xl:grid-cols-6 lg:grid-cols-6 grid-cols-1 gap-4">
      <div class="flex flex-col gap-2">
        <label>Tipo</label>
        <Dropdown
          v-model="modelModal.origen.tipdoc"
          :options="tiposDocumento"
          :disabled="inputsAvailable"
          optionLabel="name"
          optionValue="tipdoc"
          placeholder="Seleccione un tipo de documento"
          class="p-inputtext-sm w-full"
        />
        <MessageError :text="errorsModal['origen.tipdoc']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Documento</label>
        <InputText v-model="modelModal.origen.documento" class="p-inputtext-sm w-full" :disabled="inputsAvailable"/>
        <MessageError :text="errorsModal['origen.documento']"/>
      </div>
      <div class="flex flex-col gap-2 xl:col-span-4 lg:col-span-4">
        <label>Nombre Completo Remitente</label>
        <AutoComplete
          v-model="nombreRemitente"
          :suggestions="remitentesFiltrados"
          :disabled="inputsAvailable"
          @complete="buscarRemitentes($event)"
          field="nomCliente"
          optionValue="codCliente"
          @item-select="onChangeRemitente"
          inputClass="w-full p-inputtext-sm"
          placeholder="Ingrese un nombre"
        />
<!--        <InputText v-model="modelModal.origen.nombre" class="p-inputtext-sm w-full" placeholder="Ingrese un nombre"/>-->
        <MessageError :text="errorsModal['origen.nombre']"/>
      </div>
    </div>
    <div class="grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-4">
      <div class="flex flex-col gap-2">
        <label>Telefono</label>
        <InputMask
          v-model="modelModal.origen.telefono"
          class="p-inputtext-sm w-full"
          placeholder="312 233-3333"  mask="9999999999"
          :disabled="inputsAvailable"
        />
        <MessageError :text="errorsModal['origen.telefono']"/>
      </div>
      <div class="flex flex-col gap-2 ">
        <label>Correo</label>
        <InputText
          v-model="modelModal.origen.correo"
          class="p-inputtext-sm w-full"
          placeholder="ejemplo@correo.com"
          :disabled="inputsAvailable"
        />
        <MessageError :text="errorsModal['origen.correo']"/>
      </div>
    </div>
    <div class="grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-4">
      <div class="flex flex-col gap-2">
        <label>Ciudad</label>
        <AutoComplete
          v-model="nombreCiudad"
          :suggestions="ciudadesFiltradas"
          :disabled="inputsAvailable"
          @complete="buscarCiudad($event)"
          field="nomCiudad"
          optionValue="codCiudad"
          @item-select="onChangeCiudad"
          inputClass="w-full p-inputtext-sm"
          placeholder="Seleccione un ciudad"
        />
        <MessageError :text="errorsModal['origen.ciudad']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Dirección</label>
        <InputText
          v-model="modelModal.origen.direccion"
          class="p-inputtext-sm w-full"
          placeholder="Carrera 1 #10-10"
          :disabled="inputsAvailable"
        />
        <MessageError :text="errorsModal['origen.direccion']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Código Postal</label>
        <InputText v-model="modelModal.origen.postal" class="p-inputtext-sm w-full" placeholder="688809" :disabled="inputsAvailable"/>
        <MessageError :text="errorsModal['origen.postal']"/>
      </div>
    </div>
    <div class="flex gap-4">
      <div class="flex flex-col gap-2 w-full">
        <label>Descripción</label>
        <InputText v-model="modelModal.origen.descripcion" class="p-inputtext-sm w-full"
                   placeholder="Ingrese una descipción" :disabled="inputsAvailable"/>
        <MessageError :text="errorsModal['origen.descripcion']"/>
      </div>
    </div>
  </section>

  <section class="flex flex-col gap-2 pt-2">
    <span class="font-bold text-xl flex justify-center">¿A donde Envio?</span>
    <div class="grid xl:grid-cols-6 lg:grid-cols-6 grid-cols-1 gap-4">
      <div class="flex flex-col gap-2">
        <label>Tipo</label>
        <Dropdown
          v-model="modelModal.destino.tipdoc"
          :options="tiposDocumento"
          :disabled="inputsAvailable"
          optionLabel="name"
          optionValue="tipdoc"
          placeholder="Seleccione un tipo de documento"
          class="p-inputtext-sm w-full"
        />
        <MessageError :text="errorsModal['destino.tipdoc']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Documento</label>
        <InputText v-model="modelModal.destino.documento" class="p-inputtext-sm w-full"
                   placeholder="Ingrese un documento" :disabled="inputsAvailable"/>
        <MessageError :text="errorsModal['destino.documento']"/>
      </div>
      <div class="flex flex-col gap-2 xl:col-span-4 lg:col-span-4">
        <label>Nombre Completo Destinatario</label>
        <AutoComplete
          v-model="nombreDestinatario"
          :suggestions="destinatariosFiltrados"
          :disabled="inputsAvailable"
          @complete="buscarDestinatarios($event)"
          field="nomCliente"
          optionValue="codCliente"
          @item-select="onChangeDestinatario"
          inputClass="w-full p-inputtext-sm"
          placeholder="Ingrese un nombre"
        />
<!--        <InputText v-model="modelModal.destino.nombre" class="p-inputtext-sm w-full" placeholder="Ingrese un nombre"/>-->
        <MessageError :text="errorsModal['destino.nombre']"/>
      </div>
    </div>
    <div class="grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-4">
      <div class="flex flex-col gap-2">
        <label>Telefono</label>
        <InputMask
          v-model="modelModal.destino.telefono"
          class="p-inputtext-sm w-full"
          placeholder="312 233-3333"
          mask="9999999999"
          :disabled="inputsAvailable"
        />
        <MessageError :text="errorsModal['destino.telefono']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Correo</label>
        <InputText
          v-model="modelModal.destino.correo"
          class="p-inputtext-sm w-full"
          placeholder="ejemplo@correo.com"
          :disabled="inputsAvailable"
        />
        <MessageError :text="errorsModal['destino.correo']"/>
      </div>
    </div>
    <div class="grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-4">
      <div class="flex flex-col gap-2">
        <label>Ciudad</label>
        <AutoComplete
          v-model="nombreCiudadDestinatario"
          :suggestions="ciudadesFiltradasDestinatario"
          :disabled="inputsAvailable"
          @complete="buscarCiudadDestinatario($event)"
          field="nomCiudad"
          optionValue="codCiudad"
          @item-select="onChangeCiudadDestinatario"
          inputClass="w-full p-inputtext-sm"
          placeholder="Seleccione un ciudad"
        />
        <MessageError :text="errorsModal['destino.ciudad']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Dirección</label>
        <InputText
          v-model="modelModal.destino.direccion"
          class="p-inputtext-sm w-full"
          placeholder="Carrera 1 #10-10"
          :disabled="inputsAvailable"
        />
        <MessageError :text="errorsModal['destino.direccion']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Código Postal</label>
        <InputText
          v-model="modelModal.destino.postal"
          class="p-inputtext-sm w-full"
          placeholder="688809"
          :disabled="inputsAvailable"
        />
        <MessageError :text="errorsModal['destino.postal']"/>
      </div>
    </div>
    <div class="flex gap-4">
      <div class="flex flex-col gap-2 w-full">
        <label>Descripción</label>
        <InputText v-model="modelModal.destino.descripcion" class="p-inputtext-sm w-full"
                   placeholder="Ingrese una descipción" :disabled="inputsAvailable"/>
        <MessageError :text="errorsModal['destino.descripcion']"/>
      </div>
    </div>
    <div class="grid xl:grid-cols-4 lg:grid-cols-4 grid-cols-1 gap-4">
      <div class="flex flex-col gap-2 xl:col-span-2 lg:col-span-2">
        <label>Mensajero</label>
        <Dropdown
          v-model="modelModal.guia.mensajero"
          :options="mensajeros"
          :disabled="inputsAvailable"
          optionLabel="label"
          optionValue="value"
          :filter="true"
          placeholder="Seleccione un mensajero"
          :showClear="true"
          @change="onChangeMensajero"
        >
          <template #value="slotProps">
            <span v-if="slotProps.value">{{ labelMensajero }}</span>
            <span v-else>{{ slotProps.placeholder }}</span>
          </template>
          <template #option="slotProps">
            <div>{{ slotProps.option.label }}</div>
          </template>
        </Dropdown>
        <MessageError :text="errorsModal['guia.mensajero']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Valor Declarado</label>
        <InputNumber
          class="p-inputtext-sm w-full"
          inputClass="p-inputtext-sm w-full"
          v-model="modelModal.destino.declarado"
          :min="0"
          :disabled="inputsAvailable"
          mode="currency"
          currency="COP"
          locale="es-CO"
        />
        <MessageError :text="errorsModal['destino.declarado']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Valor Servicio</label>
        <InputNumber
          class="p-inputtext-sm w-full"
          inputClass="p-inputtext-sm w-full"
          v-model="modelModal.destino.total"
          :min="0"
          :disabled="inputsAvailable"
          mode="currency"
          currency="COP"
          locale="es-CO"
        />
        <MessageError :text="errorsModal['destino.total']"/>
      </div>
    </div>
    <div class="grid xl:grid-cols-4 lg:grid-cols-4 grid-cols-1 gap-4">
      <div class="flex flex-col gap-2">
        <label>Otros Valores</label>
        <InputNumber
          class="p-inputtext-sm w-full"
          inputClass="p-inputtext-sm w-full"
          v-model="modelModal.destino.otros"
          :disabled="inputsAvailable"
          :min="0"
          mode="currency"
          currency="COP"
          locale="es-CO"
        />
        <MessageError :text="errorsModal['destino.otros']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Descuento</label>
        <InputNumber
          class="p-inputtext-sm w-full"
          inputClass="p-inputtext-sm w-full"
          v-model="modelModal.destino.descuento"
          :min="0"
          :max="100"
          :disabled="inputsAvailable"
          suffix=" %"
        />
        <MessageError :text="errorsModal['destino.descuento']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Unidades</label>
        <InputText v-model="modelModal.destino.unidades" class="p-inputtext-sm" :disabled="true" :min="0"/>
        <MessageError :text="errorsModal['destino.unidades']"/>
      </div>
      <div class="flex flex-col gap-2">
        <label>Peso</label>
        <InputNumber
          class="p-inputtext-sm w-full"
          inputClass="p-inputtext-sm w-full"
          v-model="modelModal.destino.peso"
          :disabled="inputsAvailable"
          :min="0"
          suffix=" kg"
        />
        <MessageError :text="errorsModal['destino.peso']"/>
      </div>
    </div>
  </section>
  <div class="flex justify-end">
    <Button
      label="Guardar"
      icon="pi pi-save"
      @click="saveInfoEncarga"
      v-if="!inputsAvailable"
      class="p-button-success p-button-xs text-md p-inputtext-xs"
    />
    <Button
      v-else
      label="Editar"
      icon="pi pi-pencil"
      @click="clearStorageLogistica"
      class="p-button-warning p-button-xs text-md p-inputtext-xs"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import { useStore } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'encargaTuDomi',
  setup () {
    const store = useStore()
    const tiposServicio = computed(() => store.getters['controlEnvioStorage/getTiposServicios'])
    const empaques = computed(() => store.getters['controlEnvioStorage/getEmpaques'])
    const centrosCostos = computed(() => store.getters['controlEnvioStorage/getCentrosCostos'])
    const tiposDocumento = computed(() => store.getters['controlEnvioStorage/getTiposDocumentos'])
    const userEncarga = computed(() => store.getters['controlEnvioStorage/getUserEncarga'])
    const infoGestionEnvio = computed(() => store.getters['controlEnvioStorage/getGestionEnvio'])
    const infoLogistica = computed(() => store.getters['controlEnvioStorage/getInfoLogistica'])
    const inputsAvailable = computed(() => !!(infoLogistica.value.destino.length && Object.keys(infoLogistica.value.guia).length && Object.keys(infoLogistica.value.origen).length))
    const ciudades = computed(() => store.getters['controlEnvioStorage/getCiudades'])
    const mensajeros = computed(() => store.getters['controlEnvioStorage/getMensajeros'])
    const clientes = computed(() => store.getters['controlEnvioStorage/getClientes'])

    const nombreCiudad = ref('')
    const nombreCiudadDestinatario = ref('')
    const labelMensajero = ref('')
    const ciudadesFiltradas = ref([])
    const ciudadesFiltradasDestinatario = ref([])
    const nombreRemitente = ref('')
    const remitentesFiltrados = ref([])
    const nombreDestinatario = ref('')
    const destinatariosFiltrados = ref([])
    const schemaModal = yup.object({
      guia: yup.object().shape({
        contado: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        tipo: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        empaque: yup.number().when('tipo', (val) => {
          if (val === 1 || val === 10 || val === 24) {
            return yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido')
          } else {
            return yup.string().nullable()
          }
        }),
        mensajero: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        observaciones: yup.string().nullable()
      }),
      origen: yup.object().shape({
        centro: yup.number().nullable(),
        sucursal: yup.number().nullable(),
        tipdoc: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        documento: yup.string().nullable().required('El campo es requerido'),
        nombre: yup.string().nullable().required('El campo es requerido'),
        telefono: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        correo: yup.string().email('El campo debe ser un correo valido').nullable().required('El campo es requerido').typeError('El campo debe ser un correo valido'),
        ciudad: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        direccion: yup.string().nullable().required('El campo es requerido'),
        postal: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        lat: yup.number(),
        lon: yup.number(),
        descripcion: yup.string().nullable().required('El campo es requerido')
      }),
      destino: yup.object().shape({
        sucursal: yup.number().nullable(),
        tipdoc: yup.number().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        documento: yup.string().nullable().required('El campo es requerido'),
        nombre: yup.string().nullable().required('El campo es requerido'),
        telefono: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        correo: yup.string().email('El campo debe ser un correo valido').nullable().required('El campo es requerido').typeError('El campo debe ser un correo valido'),
        ciudad: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        direccion: yup.string().nullable().required('El campo es requerido'),
        postal: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        lat: yup.number(),
        lon: yup.number(),
        descripcion: yup.string().nullable().required('El campo es requerido'),
        declarado: yup.number().nullable().min(1, 'Debe ser mayor a 0').required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        unidades: yup.number().nullable().min(1, 'Debe ser mayor a 0').required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        peso: yup.number().nullable().min(1, 'Debe ser mayor a 0').required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        volumen: yup.number(),
        descuento: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        otros: yup.number().nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        base: yup.number().min(1, 'Debe ser mayor a 0').nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        seguro: yup.number().min(1, 'Debe ser mayor a 0').nullable().required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        total: yup.number().nullable().min(1, 'Debe ser mayor a 0').required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        programado: yup.number(),
        fecha: yup.date(),
        idaregreso: yup.number(),
        entresedes: yup.number(),
        segundaentrega: yup.number(),
        radicar: yup.number(),
        nivel0: yup.number(),
        empaque: yup.number().nullable(true)
      })
    })

    const {
      errors: errorsModal,
      values: modelModal,
      handleSubmit: handleSubmitModal
    } = useForm({
      validationSchema: schemaModal
    })
    useField('guia', null, {
      initialValue: {
        contado: 1,
        tipo: null,
        empaque: 1,
        mensajero: null,
        observaciones: ''
      }
    })
    useField('origen', null, {
      initialValue: {
        centro: 0,
        sucursal: 0,
        tipdoc: 2,
        documento: '900249425',
        nombre: null,
        telefono: null,
        correo: null,
        ciudad: null,
        direccion: null,
        postal: null,
        lat: 1,
        lon: 1,
        descripcion: null
      }
    })
    useField('destino', null, {
      initialValue: {
        sucursal: null,
        tipdoc: null,
        documento: null,
        nombre: null,
        telefono: null,
        correo: null,
        ciudad: null,
        direccion: null,
        postal: null,
        lat: 1,
        lon: 1,
        descripcion: null,
        radicado: '',
        declarado: 0,
        unidades: 0,
        peso: 0,
        volumen: 0,
        descuento: 0,
        otros: 0,
        base: 1,
        seguro: 1,
        total: 0,
        programado: 1,
        fecha: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        contra: 0,
        idaregreso: 0,
        entresedes: 0,
        segundaentrega: 0,
        radicar: 0,
        nivel0: 0,
        empaque: null,
        frio: 0,
        urgente: 0,
        ayudantes: 1
      }
    })

    const saveInfoEncarga = handleSubmitModal((values) => {
      values.destino = [{ ...values.destino }]
      store.commit('controlEnvioStorage/setInfoLogistica', values)
    })

    const clearStorageLogistica = () => {
      store.commit('controlEnvioStorage/setInfoLogistica', { destino: [], guia: {}, origen: {} })
    }
    const buscarCiudad = ({ query }) => {
      ciudadesFiltradas.value = ciudades.value.filter((ciudad) => {
        return ciudad.nomCiudad.toLowerCase().includes(query.toLowerCase())
      })
    }

    const buscarCiudadDestinatario = ({ query }) => {
      ciudadesFiltradasDestinatario.value = ciudades.value.filter((ciudad) => {
        return ciudad.nomCiudad.toLowerCase().includes(query.toLowerCase())
      })
    }
    const buscarRemitentes = ({ query }) => {
      if (modelModal.origen.tipdoc !== '') {
        remitentesFiltrados.value = clientes.value.filter((c) => {
          return c.nomCliente.toLowerCase().includes(query.toLowerCase()) && c.codTipdoc === modelModal.origen.tipdoc
        })
      }
    }

    const buscarDestinatarios = ({ query }) => {
      if (modelModal.destino.tipdoc !== '') {
        destinatariosFiltrados.value = clientes.value.filter((c) => {
          return c.nomCliente.toLowerCase().includes(query.toLowerCase()) && c.codTipdoc === modelModal.destino.tipdoc
        })
      }
    }

    const onChangeCiudad = ({ value }) => {
      modelModal.origen.ciudad = value.codCiudad
      nombreCiudad.value = value.nomCiudad
    }

    const onChangeCiudadDestinatario = ({ value }) => {
      modelModal.destino.ciudad = value.codCiudad
      nombreCiudadDestinatario.value = value.nomCiudad
    }
    const onChangeRemitente = ({ value }) => {
      modelModal.origen.nombre = value.nomCliente
      nombreRemitente.value = value.nomCliente
      if (value.codCiudad) {
        modelModal.origen.ciudad = value.codCiudad
        nombreCiudad.value = value.nomCiudad
      }

      if (!['***', '**'].includes(value.telCliente)) {
        let telVal = value.telCliente
        if (typeof value.telCliente === 'string') {
          telVal = parseInt(value.telCliente.replaceAll(' ', '').split(' - ')[0])
        }
        modelModal.origen.telefono = telVal
      }
      if (value.dirCliente && !modelModal.origen.direccion) {
        modelModal.origen.direccion = value.dirCliente
      }
      if (value.codPostal) {
        modelModal.origen.postal = value.codPostal
      }
    }

    const onChangeDestinatario = ({ value }) => {
      modelModal.destino.nombre = value.nomCliente
      nombreDestinatario.value = value.nomCliente
      if (value.codCiudad) {
        modelModal.destino.ciudad = value.codCiudad
        nombreCiudadDestinatario.value = value.nomCiudad
      }

      if (!['***', '**'].includes(value.telCliente)) {
        let telVal = value.telCliente
        if (typeof value.telCliente === 'string') {
          telVal = parseInt(value.telCliente.replaceAll(' ', '').split(' - ')[0])
        }
        modelModal.destino.telefono = telVal
      }
      if (value.dirCliente && !modelModal.destino.direccion) {
        modelModal.destino.direccion = value.dirCliente
      }
      if (value.codPostal) {
        modelModal.destino.postal = value.codPostal
      }
      if (value.corCliente && value.corCliente.indexOf('@') !== -1) {
        modelModal.destino.correo = value.corCliente
      }
    }

    const onChangeMensajero = ({ value }) => {
      const { label } = mensajeros.value.find(mensajero => mensajero.value === value)
      labelMensajero.value = label
    }

    const setInfoUser = () => {
      modelModal.origen.correo = userEncarga.value
    }
    const setDataEnvioForm = () => {
      if (!['', null, undefined, false].includes(userEncarga.value)) {
        modelModal.destino.unidades = infoGestionEnvio.value.boxes.length
        modelModal.origen.direccion = infoGestionEnvio.value.datosLogistica.dirBodOrigen
        modelModal.destino.direccion = infoGestionEnvio.value.datosLogistica.dirBodDestino
        /* hay cajas con cadena de frío */
        if (infoGestionEnvio.value.boxes.some(box => box.tipo === 1)) modelModal.destino.frio = 1
      }
    }

    onMounted(() => {
      setInfoUser()
      setDataEnvioForm()
    })

    watch(() => userEncarga.value, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        setInfoUser()
      }
    }, { deep: true })

    watch(() => infoGestionEnvio.value, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        setDataEnvioForm()
      }
    }, { deep: true })

    return {
      onChangeMensajero,
      onChangeCiudad,
      onChangeCiudadDestinatario,
      buscarCiudad,
      buscarCiudadDestinatario,
      buscarRemitentes,
      onChangeRemitente,
      buscarDestinatarios,
      onChangeDestinatario,
      clearStorageLogistica,
      userEncarga,
      infoGestionEnvio,
      labelMensajero,
      empaques,
      saveInfoEncarga,
      errorsModal,
      modelModal,
      tiposServicio,
      centrosCostos,
      tiposDocumento,
      ciudades,
      ciudadesFiltradas,
      ciudadesFiltradasDestinatario,
      nombreCiudad,
      nombreCiudadDestinatario,
      mensajeros,
      nombreRemitente,
      remitentesFiltrados,
      nombreDestinatario,
      destinatariosFiltrados,
      infoLogistica,
      inputsAvailable

    }
  }
}
</script>

<style scoped>

</style>
